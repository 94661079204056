import './App.css';
import ReactPlayer from 'react-player';
import { InlineWidget } from "react-calendly";
import { Carousel } from 'react-responsive-carousel';
import Harvard from "../src/icons/Harvard_University_logo.png";
import Berkeley from "../src/icons/University_of_California,_Berkeley-Logo.png";
import Sydney from "../src/icons/b_University_of_Sydney_b.png";
import Melbourne from "../src/icons/the-university-of-melbourne-logo-png-transparent.png";
import Stanford from "../src/icons/Stanford-Emblem.png";
import UN from "../src/icons/united-nations-logo-9CBFC2E65F-seeklogo.com.png";
import Purdue from "../src/icons/Purdue_University_system_logo.svg.png";
import Babson from "../src/icons/Babson-logo.png";
import iitBombay from "../src/icons/logoiitbombay.png";
import nift from "./icons/NIFT_official_logo.png";
import isro from "./icons/isro.png";
import about1 from "./images/about.png";
import about2 from "./images/about2.png";
import T2 from "./images/T2.png";
import T1 from "./images/T1.png";
import c1 from "./images/c1.png";
import c2 from "./images/c2.png";
import c3 from "./images/c3.png";
import c4 from "./images/c4.png";
import c5 from "./images/c5.png";
import c6 from "./images/c6.png";
import c7 from "./images/c7.png";
import c8 from "./images/c8.png";
import T3 from "./images/T3.png";
import favicon from "./images/favicon.png";
import sahaj from "./images/sahaj.png";
import happiness from "./images/happiness.png";
import srisri from "./images/srisri.png";
import fb from "../src/icons/fbD.png";
import fbT from "../src/icons/fbT.png";
import linkedin from "../src/icons/LinkedinD.png";
import linkedinT from "../src/icons/LinkedinT.png";
import insta from "../src/icons/instaD.png";
import instaT from "../src/icons/instaT.png";
import {useEffect, useState} from "react";
import logo2 from "./icons/ManasRam.svg";
import Modal from 'react-modal';
import 'react-responsive-carousel/lib/styles/carousel.min.css';

// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';
// Import Swiper styles
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';

// import './styles.css';

// import required modules
import { Autoplay, Pagination, Navigation } from 'swiper/modules';

Modal.setAppElement(document.getElementById('root'));

const customStyles = {
    content: {
        top: '50%',
        left: '50%',
        right: 'auto',
        bottom: 'auto',
        marginRight: '-50%',
        transform: 'translate(-50%, -50%)',
        backgroundColor: '#9F2B00'
    },
};



function App() {

    // const [player1, setPlayer1] = useState(false);
    // const [player2, setPlayer2] = useState(false);
    // const [player3, setPlayer3] = useState(false),
       const [modalIsOpen, setIsOpen] = useState(false);

    useEffect(() => {
        let form = document.getElementById("myForm");
        function handleForm(event)
        { event.preventDefault(); }
        form.addEventListener('submit', handleForm);
    }, []);



    const openModal =() => {
        setIsOpen(true);
        setTimeout(() => {closeModal()}, 3000);
    }

    const closeModal = () => {
        setIsOpen(false);
    }


    const onSubmit = () => {
        document.getElementById("submit").onclick = function() {
            let allAreFilled = true;
            document.getElementById("myForm").querySelectorAll("[required]").forEach(function(i) {
                if (!allAreFilled) return;
                if (!i.value) { allAreFilled = false;  return; }
            })
            if (!allAreFilled) {
                // alert('Fill all the fields');
            }
            else{
                openModal();
            }
        };

    }

    return (
        <div className="wrapper">
            <header className="header">
                <nav className="navbar navbar-expand-lg navbar-light bg-light">
                    {/*<a className="navbar-brand" href="#">Manas Ram</a>*/}
                    <img src={logo2}/>
                    <button className="navbar-toggler" type="button" data-toggle="collapse"
                            data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                            aria-expanded="false" aria-label="Toggle navigation">
                        <span className="navbar-toggler-icon"></span>
                    </button>

                    <div className="collapse navbar-collapse" id="navbarSupportedContent">
                        <ul className="navbar-nav ml-auto">
                            <li className="nav-item">
                                <a className="nav-link" href="#about">About</a>
                            </li>
                            {/*<li className="nav-item">*/}
                            {/*    <a className="nav-link" href="#courses">Courses</a>*/}
                            {/*</li>*/}
                            <li className="nav-item">
                                <a className="nav-link" href="#BookASession">Book A Session</a>
                            </li>
                            <li className="nav-item">
                                <a className="nav-link" href="#contact">Contact</a>
                            </li>

                            <ul className="soc-media-ul pl-3">
                                <a href={"https://www.facebook.com/manas.ram.73"}><img src={fbT}/></a>
                                <a href={"https://www.instagram.com/manasram1/"}><img src={instaT}/></a>
                                {/*<a href={"https://twitter.com/manasram"}><img src={twitterT}/></a>*/}
                                <a href={"https://www.linkedin.com/in/manas-ram-60a27a9a"}><img src={linkedinT}/></a>
                            </ul>
                        </ul>
                    </div>
                </nav>
            </header>

            <section id="home" className="sec-main">
                {/*<p className="main-heading p-3">*/}
                {/*    Together we rise,in the self’s embrace,<br/>*/}
                {/*    One consciousness, one love, one human race.</p>*/}
                <p className="main-heading p-3">
                    Be who you are. There is only one of you!
                </p>
            </section>


            <section id="about" className="sec-about">


            {/*    <div className="container">*/}
            {/*        <h2>About Manas Ram</h2>*/}
            {/*        <hr/>*/}
            {/*        <div className="row">*/}
            {/*            <div className={"col-sm-6"} style={{display:"ruby"}}>*/}
            {/*                <input type="checkbox" className="read-more-state" id="post-2" />*/}
            {/*                <div className="read-more-wrap">*/}
            {/*                    <div style={{textAlign:"center"}} className={"about orange"}><b>A Beacon of Universal Wisdom and Empathetic Leadership.</b></div>*/}

            {/*                    <p className={"about"}>Manas Ram's stature as an entrepreneur, investor, philanthropist, and venture capitalist is just the tip of the iceberg in his multifaceted journey. His profound contribution*/}
            {/*                        to personality development and youth empowerment has been shaping lives globally for over a decade.*/}
            {/*                        Utilizing a blend of leadership development, mind management, yoga, and meditation,*/}
            {/*                        he has mastered the art of inspiring transformation.</p>*/}

            {/*                    <p className={"about"}>Rooted deeply in the Vedic traditions, Ram's wisdom surpasses boundaries of race, religion, caste, and creed.*/}
            {/*                        His presence radiates a sense of belonging and acceptance, making everyone feel at home.*/}
            {/*                        Remarkably, his insights into ancient scriptures, which he could quote verbatim as a child prodigy without formal study,*/}
            {/*                        reflect his extraordinary intellect and spiritual depth. His knowledge extends to ancillary sciences like Ayurveda, Vastu, Astrology,*/}
            {/*                        Mantra Shastra, Vedangas, various healing methods, and the Upanishads, showcasing his diverse expertise.</p>*/}

            {/*                    <p className={"about read-more-target"}>As a Cognitive Behavioral Therapist, Neuro-Linguistic Practitioner, Reiki Master Healer, Life Coaching expert, and Productivity Coach,*/}
            {/*                        Ram is celebrated not just for his skills but for his extreme empathy and the profound love felt in his presence.</p>*/}

            {/*                    <p className={"about read-more-target"}>He has left his mark in prestigious institutions worldwide, including the United Nations, Harvard, MIT, UC Berkeley, Purdue University,*/}
            {/*                        Babson College, and universities in Melbourne, Monash, Sydney, and Universiti Malaya.*/}
            {/*                        In India, his influence radiates through the Indian Institutes of Technology in Hyderabad and Mumbai,*/}
            {/*                        IIITs, NIFTs, Medical Universities, etc.</p>*/}

            {/*                    <p className={"about read-more-target"}>His teachings, revered by a vast array of individuals from venture capitalists to students, military officials, government officers, professors, celebrities,*/}
            {/*                        changemakers, and business professionals, are a testament to his universal appeal and transformative impact.</p>*/}

            {/*                    <p className={"about read-more-target"}>Manas Ram is not just a leader but a guiding light in the journey towards self-empowerment and excellence, embodying the power of empathy,*/}
            {/*                        wisdom, and impactful change in creating a harmonious world.</p>*/}
            {/*                </div>*/}
            {/*                <label htmlFor="post-2" className="read-more-trigger"></label>*/}
            {/*            </div>*/}
            {/*            <div className={"col-sm-6"}>*/}
            {/*                <img style={{width: "100%", padding:"10px", transform: "rotate(6deg)", borderRadius:"5%"}} src={about2}/>*/}
            {/*                <img style={{width: "100%", padding:"10px",transform: "rotate(-7deg)", borderRadius:"5%"}} src={about1}/>*/}
            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</section>*/}
                <div className="container">
                    <h2>About Manas Ram</h2>
                    <hr/>
                    <div className="row">
                        <div className={"col-sm-6"} style={{display:"ruby"}}>
                            <input type="checkbox" className="read-more-state" id="post-2" />
                            <div className="">
                                <div style={{textAlign:"center"}} className={"about orange"}><b>A Beacon of Universal Wisdom and Empathetic Leadership.</b></div>

                                <p className={"about"}>Manas Ram's stature as an entrepreneur, investor, philanthropist, and venture capitalist is just the tip of the iceberg in his multifaceted journey.
                                </p>

                                <p className={"about"}>As a Cognitive Behavioral Therapist, Neuro-Linguistic Practitioner, Reiki Master Healer, Life Coaching expert, and Productivity Coach, Ram has exceptional empathy and inspires with his skills and warmth.</p>

                                <p className={"about"}>Rooted deeply in the Vedic traditions, Ram's wisdom surpasses boundaries of race, religion, caste, and creed. His presence radiates a sense of belonging and acceptance, making everyone feel at home..</p>

                                <p className={"about"}>He is renowned for his work at prestigious institutions globally. He Impacted institutions worldwide (UN, Harvard, MIT, etc.) and across India (IITs, NIFTs, etc.).</p>

                                <p className={"about"}>Manas Ram is not just a leader but a guiding light in the journey towards self-empowerment and excellence, and impactful change in creating a harmonious world.
                                </p>
                            </div>
                            {/*<label htmlFor="post-2" className="read-more-trigger"></label>*/}
                        </div>
                        <div className={"col-sm-6"}>
                            <img style={{width: "100%", padding:"10px", transform: "rotate(6deg)", borderRadius:"5%"}} src={about2}/>
                            {/*<img style={{width: "100%", padding:"10px",transform: "rotate(-7deg)", borderRadius:"5%"}} src={about1}/>*/}
                        </div>
                    </div>
                </div>
            </section>


            {/*<section>*/}
            {/*    <Swiper*/}
            {/*        slidesPerView={1}*/}
            {/*        spaceBetween={30}*/}
            {/*        loop={true}*/}
            {/*        pagination={{*/}
            {/*            clickable: true,*/}
            {/*        }}*/}
            {/*        autoplay={{*/}
            {/*            delay: 2500,*/}
            {/*            disableOnInteraction: false,*/}
            {/*        }}*/}
            {/*        navigation={true}*/}
            {/*        modules={[Autoplay,Pagination, Navigation]}*/}
            {/*        className="mySwiper"*/}
            {/*    >*/}
            {/*        <SwiperSlide><img src={c1}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c2}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c3}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c4}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c5}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c6}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c7}/></SwiperSlide>*/}
            {/*        <SwiperSlide><img src={c8}/></SwiperSlide>*/}

            {/*    </Swiper>*/}
            {/*</section>*/}

            <section>
                <Swiper
                    slidesPerView={1}
                    spaceBetween={30}
                    loop={true}
                    pagination={{
                        clickable: true,
                    }}
                    navigation={true}
                    autoplay={{
                                delay: 2500,
                                disableOnInteraction: false,
                            }}
                    // modules={[Pagination, Navigation]}
                    modules={[Autoplay, Pagination, Navigation]}
                    className="mySwiper"
                >
                    {/*<SwiperSlide>*/}
                    {/*    <div className="imgContainer">*/}
                    {/*        <img src={c1}/>*/}
                    {/*        <p class="overlay-text">*/}
                    {/*            “ Invest in what aligns with your values, not what is lucrative “*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}
                    <SwiperSlide>
                        <div className="imgContainer">
                            <img className={"swiper-slider-image"} src={c2}/>
                            <p class="overlay-text-info1 img1">
                                - Alyssa Dver, Founder, American Confidence Institute (ACI)
                            </p>
                            <p class="overlay-text first-image">
                                “ Humility with confidence.Conviction with Candor.The Recipe of Excellence ”<br/>
                                <img className={"overlay-text-img"} src={favicon}/>
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="imgContainer">
                            <img className={"swiper-slider-image"} src={c3}/>
                            <p className="overlay-text-info1 img1">
                                - Bruce Friedrich, Founder of The Good Food Institute
                            </p>
                            <p class="overlay-text first-image">
                                “ The simplest of signs are the greatest indicators of good health.Just be conscious of what your body is saying “<br/>
                                <img className={"overlay-text-img"} src={favicon}/>
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="imgContainer">
                            <img className={"swiper-slider-image"} src={c4}/>
                            <p class="overlay-text first-image">
                                “ Prioritize yourself.Your life runs because of your presence.Honour it “<br/>
                                <img className={"overlay-text-img"} src={favicon}/>
                            </p>
                        </div>
                    </SwiperSlide>
                    {/*<SwiperSlide>*/}
                    {/*    <div className="imgContainer">*/}
                    {/*        <img src={c5}/>*/}
                    {/*        <p className="overlay-text-info">*/}
                    {/*            - Hans Zimmer, Oscar-winning composer*/}
                    {/*        </p>*/}
                    {/*        <p class="overlay-text">*/}
                    {/*            “ You create to express, not impress! “*/}
                    {/*        </p>*/}
                    {/*    </div>*/}
                    {/*</SwiperSlide>*/}
                    <SwiperSlide>
                        <div className="imgContainer">
                            <img className={"swiper-slider-image"} src={c6}/>
                            <p className="overlay-text-info1 img1 imgR">
                                - Radhika Gupta,<br/>MD & CEO, Edelweiss MF

                            </p>
                            <p class="overlay-text first-image">
                                “ You breed where your attention is.Put it wisely,and you will manifest success,and fulfilment in life “<br/>
                                <img className={"overlay-text-img"} src={favicon}/>
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="imgContainer">
                            <img className={"swiper-slider-image"} src={c7}/>
                            <p className="overlay-text-info1 img1 imgInshorts">
                                -  Azhar Iqubal, <br/>Co-founder & CEO,Inshorts
                            </p>
                            <p class="overlay-text last-image-2">
                                “ You don’t build a consumer base.You build a community.With them with your heart,not with your brains “<br/>
                                <img className={"overlay-text-img"} src={favicon}/>
                            </p>
                        </div>
                    </SwiperSlide>
                    <SwiperSlide>
                        <div className="imgContainer">
                            <img className={"swiper-slider-image"} src={c8}/>
                            <p className="overlay-text-info1 img1">
                                - Terrence Keeley, <br/>Chairman & CEO,1PointSix
                            </p>
                            <p class="overlay-text last-image-1">
                                “ Life wakes you up.Purpose awakens you!Passion drives you.Dispassion let you enjoy the ride “<br/>
                                <img className={"overlay-text-img"} src={favicon}/>
                            </p>
                            {/*<img className={"overlay-text-img"} src={favicon}/>*/}
                        </div>
                    </SwiperSlide>

                </Swiper>
            </section>

            {/*<section id="courses">*/}
            {/*<div className="page-event">*/}
            {/*    <div className="container">*/}
            {/*        <div className="upcoming-sec">*/}
            {/*            <div className="heading">Upcoming Courses</div>*/}
            {/*        </div>*/}
            {/*        <div className="upcoming-event-list">*/}
            {/*            <div className="event-block">*/}
            {/*                <div className="row">*/}
            {/*                    <div className="col-lg-2 sec-1">*/}
            {/*                        <table>*/}
            {/*                            <tr>*/}
            {/*                                <td>*/}
            {/*                                    <div className="month">Jan</div>*/}
            {/*                                    <div className="month-date-devider"></div>*/}
            {/*                                    <div className="date">21</div>*/}
            {/*                                </td>*/}
            {/*                                <td className="title">Sri Sri Yoga</td>*/}
            {/*                            </tr>*/}
            {/*                        </table>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-sm-6 col-lg-4 sec-2">*/}
            {/*                      <img src={srisri}/>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-lg-5 sec-3">*/}
            {/*                        <div className="title">Sri Sri Yoga</div>*/}
            {/*                        <div className="venue">*/}
            {/*                            <table>*/}
            {/*                                /!*<tr>*!/*/}
            {/*                                /!*    <td><i className="fa fa-map-marker"></i></td>*!/*/}
            {/*                                /!*    <td>*!/*/}
            {/*                                        /!*<div className="teacherName">Sainikpuri</div>*!/*/}
            {/*                                        /!*<div className="dim-color">*!/*/}
            {/*                                        /!*    <a href="https://www.google.co.in" target="blank">Get Directions</a>*!/*/}
            {/*                                        /!*</div>*!/*/}
            {/*                                    /!*</td>*!/*/}
            {/*                                /!*</tr>*!/*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                        <div class="time">*/}
            {/*                            <table>*/}
            {/*                                <tr>*/}
            {/*                                    <td><i class="fa fa-clock-o"></i></td>*/}
            {/*                                    <td>*/}
            {/*                                        <div style={{color:"#9F2B00"}}>Saturday, Jan 27, 2024 at 5:30 PM</div>*/}
            {/*                                        <div data-livestamp="1517054400" class="dim-color"></div>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                        <div class="sort-story">Embrace the practice of yoga guided by ancient wisdom and modern expertise. Where movement meets peace. Unfurl your potential from the Inside Out. </div>*/}
            {/*                        <div class="group-of-btn">*/}
            {/*                            <a href="https://www.google.com" target="blank" class="btn book-ticket">Register Now</a>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}
            {/*                <hr/>*/}
            {/*                <div className="row paddinTopEvent">*/}
            {/*                    <div className="col-lg-2 sec-1">*/}
            {/*                        <table>*/}
            {/*                            <tr>*/}
            {/*                                <td>*/}
            {/*                                    <div className="month">Jan</div>*/}
            {/*                                    <div className="month-date-devider"></div>*/}
            {/*                                    <div className="date">27</div>*/}
            {/*                                </td>*/}
            {/*                                <td className="title">Happiness Program</td>*/}
            {/*                            </tr>*/}
            {/*                        </table>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-sm-6 col-lg-4 sec-2">*/}
            {/*                        <img src={happiness}/>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-lg-5 sec-3">*/}
            {/*                        <div className="title">Happiness Program</div>*/}
            {/*                        <div className="venue">*/}
            {/*                            <table>*/}
            {/*                                /!*<tr>*!/*/}
            {/*                                    /!*<td><i className="fa fa-map-marker"></i></td>*!/*/}
            {/*                                    /!*<td>*!/*/}
            {/*                                        /!*<div className="teacherName">Sainikpuri</div>*!/*/}
            {/*                                        /!*<div className="dim-color">*!/*/}
            {/*                                        /!*    <a href="https://www.google.co.in" target="blank">Get Directions</a>*!/*/}
            {/*                                        /!*</div>*!/*/}
            {/*                                    /!*</td>*!/*/}
            {/*                                /!*</tr>*!/*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                        <div className="time">*/}
            {/*                            <table>*/}
            {/*                                <tr>*/}
            {/*                                    <td><i className="fa fa-clock-o"></i></td>*/}
            {/*                                    <td>*/}
            {/*                                        <div style={{color:"#9F2B00"}}>Saturday, Jan 27, 2024 at 5:30 PM</div>*/}
            {/*                                        <div data-livestamp="1517054400" className="dim-color"></div>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                        <div className="sort-story">Feel stressed, overwhelmed, or out of balance? Explore the transformative power of breath, meditation, and mindfulness in a nurturing environment. Unleash the happiness within. This isn’t just a feel-good workshop- it’s a life-changing experience.</div>*/}
            {/*                        <div className="group-of-btn">*/}
            {/*                            <a href="https://www.google.com" target="blank" className="btn book-ticket">Register Now</a>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}

            {/*                <hr/>*/}
            {/*                <div className="row paddinTopEvent">*/}
            {/*                    <div className="col-lg-2 sec-1">*/}
            {/*                        <table>*/}
            {/*                            <tr>*/}
            {/*                                <td>*/}
            {/*                                    <div className="month">Jan</div>*/}
            {/*                                    <div className="month-date-devider"></div>*/}
            {/*                                    <div className="date">27</div>*/}
            {/*                                </td>*/}
            {/*                                <td className="title">Sahaj Samadhi Meditation</td>*/}
            {/*                            </tr>*/}
            {/*                        </table>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-sm-6 col-lg-4 sec-2">*/}
            {/*                        <img src={sahaj}/>*/}
            {/*                    </div>*/}
            {/*                    <div className="col-lg-5 sec-3">*/}
            {/*                        <div className="title">Sahaj Samadhi Meditation</div>*/}
            {/*                        <div className="venue">*/}
            {/*                            <table>*/}
            {/*                                /!*<tr>*!/*/}
            {/*                                    /!*<td><i className="fa fa-map-marker"></i></td>*!/*/}
            {/*                                    /!*<td>*!/*/}
            {/*                                        /!*<div className="teacherName">Sainikpuri</div>*!/*/}
            {/*                                        /!*<div className="dim-color">*!/*/}
            {/*                                        /!*    <a href="https://www.google.co.in" target="blank">Get Directions</a>*!/*/}
            {/*                                        /!*</div>*!/*/}
            {/*                                    /!*</td>*!/*/}
            {/*                                /!*</tr>*!/*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                        <div className="time">*/}
            {/*                            <table>*/}
            {/*                                <tr>*/}
            {/*                                    <td><i className="fa fa-clock-o"></i></td>*/}
            {/*                                    <td>*/}
            {/*                                        <div style={{color:"#9F2B00"}}>Saturday, Jan 27, 2024 at 5:30 PM</div>*/}
            {/*                                        <div data-livestamp="1517054400" className="dim-color"></div>*/}
            {/*                                    </td>*/}
            {/*                                </tr>*/}
            {/*                            </table>*/}
            {/*                        </div>*/}
            {/*                        <div className="sort-story">Experience the ancient practice of meditation guided by expert instruction and timeless wisdom. Ditch the mind chatter. Dive deep into unshakeable calm. Discover your true self.*/}
            {/*                        </div>*/}
            {/*                        <div className="group-of-btn">*/}
            {/*                            <a href="https://www.google.com" target="blank" className="btn book-ticket">Register Now</a>*/}
            {/*                        </div>*/}
            {/*                    </div>*/}
            {/*                </div>*/}


            {/*            </div>*/}
            {/*        </div>*/}
            {/*    </div>*/}
            {/*</div>*/}
            {/*</section>*/}



            <section id="workshops" className="sec-workshops">
                <div className="container sliderDiv">
                    <h2>Led Programs At</h2>
                    <hr />
                </div>
            <div className="logos">
                <div className="logos-slide">
                    <img src={Harvard}/>
                    <img src={UN}/>
                    <img src={Sydney}/>
                    <img src={Melbourne}/>
                    <img src={Stanford}/>
                    <img src={Berkeley}/>
                    <img src={nift}/>
                    <img src={Purdue}/>
                    <img src={Babson}/>
                    <img src={iitBombay}/>
                    <img src={isro}/>
                </div>

                <div className="logos-slide">
                    <img src={Harvard}/>
                    <img src={UN}/>
                    <img src={Sydney}/>
                    <img src={Melbourne}/>
                    <img src={Stanford}/>
                    <img src={Berkeley}/>
                    <img src={nift}/>
                    <img src={Purdue}/>
                    <img src={Babson}/>
                    <img src={iitBombay}/>
                    <img src={isro}/>
                </div>

                <div className="logos-slide">
                    <img src={Harvard}/>
                    <img src={UN}/>
                    <img src={Sydney}/>
                    <img src={Melbourne}/>
                    <img src={Stanford}/>
                    <img src={Berkeley}/>
                    <img src={nift}/>
                    <img src={Purdue}/>
                    <img src={Babson}/>
                    <img src={iitBombay}/>
                    <img src={isro}/>
                </div>
            </div>
            </section>




            <section id="testimonials" className="sec-testimonials Testimonials">
                <div className="container">
                    <h2>Testimonials</h2>

                    <hr />

                    <div className="row">
                        <ReactPlayer className="col-sm-4 p-2" url='https://vimeo.com/921054432' light={true} playing={true} controls={true}/>
                        <ReactPlayer className="col-sm-4 p-2" url='https://vimeo.com/921051384' light={true} playing={true} controls={true}/>
                        <ReactPlayer className="col-sm-4 p-2" url='https://vimeo.com/922014787' light={true} playing={true} controls={true}/>

                    </div>
                </div>
            </section>

            <section id="testimonials" className="testimonial">
                <div className="container">
                    <div className="row">
                        <div className="col-lg-6 d-none d-lg-block">
                            <ol className="carousel-indicators tabs">
                                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active">
                                    <figure>
                                        <img src={T1} className="img-fluid" alt=""/>
                                    </figure>
                                </li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="1">
                                    <figure>
                                        <img src={T2} className="img-fluid" alt=""/>
                                    </figure>
                                </li>
                                <li data-target="#carouselExampleIndicators" data-slide-to="2">
                                    <figure>
                                        <img src={T3} className="img-fluid" alt=""/>
                                    </figure>
                                </li>
                            </ol>
                        </div>
                        <div className="col-lg-6 d-flex justify-content-center align-items-center">
                            <div id="carouselExampleIndicators" data-interval="false" className="carousel slide" data-ride="carousel">
                                <h3 className={"testimonial-heading"}>STRAIGHT FROM THE HEART</h3>
                                {/*<h1>TESTIMONIALS</h1>*/}
                                <div className="carousel-inner">
                                    <div className="carousel-item active">
                                        <div className="quote-wrapper">
                                            <p>When I first attended The art of Living's Happiness program with Manas Ram Sir, I felt like Family and that sense of belonging ness has never left till date. The amount of knowledge he has about consciousness and self and vedic
                                                literature has inspired me to  take the spiritual path and bring light into many other lives.</p>
                                            <h3>Divya Chillara</h3>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="quote-wrapper">
                                            <p>A perfect blend of spirituality & science and depth of wisdom in the ancient scriptures makes his sessions all the more unique and Interesting.
                                                I consider myself extremely fortunate to have come across a teacher like him.</p>
                                            <h3>Nikhitha Reddy</h3>
                                        </div>
                                    </div>
                                    <div className="carousel-item">
                                        <div className="quote-wrapper">
                                            <p>It was a joy to be a student in Manas' sessions. Wise beyond his years, he combines the depth of a sage with the humour of a stand-up comedian. He is a natural teacher, and his brilliance shone through as he made deep or complex concepts simple to
                                                understand. In his presence, I felt strong, inspired and free.</p>
                                            <h3>Julia Arbuckle</h3>
                                        </div>
                                    </div>
                                </div>
                                <ol className="carousel-indicators indicators">
                                    <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                                    <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
                                </ol>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

            <section id="courses">
                <div className="container">
                    <h2>Courses with Manas</h2>

                    <hr />
                    <div className={"row"}></div>
                    <img className={"col-sm-4"} src={srisri} alt={""}/>
                    <img className={"col-sm-4"} src={happiness} alt={""}/>
                    <img className={"col-sm-4"} src={sahaj} alt={""}/>
                </div>

                <div className="group-of-btn">
                    <a href="https://linktr.ee/manasram" target="blank" className="btn book-ticket">Explore More</a>
                </div>
            </section>



            <section id={"BookASession"}>
                <div className="container">
                    <h2>Book A Session With Manas</h2>

                    <hr/>
                    <InlineWidget url="https://calendly.com/manasramoutreach/30min?hide_event_type_details=1&hide_gdpr_banner=1" />
                    {/*<div className="calendly-inline-widget" data-url="https://calendly.com/manasramoutreach/30min?hide_event_type_details=1&hide_gdpr_banner=1" style={{minWidth:"320px",height:"700px"}}></div>*/}
                </div>
            </section>

            <div>
                {/*<button onClick={openModal}>Open Modal</button>*/}
                <Modal
                    isOpen={modalIsOpen}
                    style={customStyles}
                    contentLabel="Example Modal"
                >

                    <div className="success-page">
                        <h2>Thank you for your message!</h2>
                        <p>We will get back to you at the earliest.</p>
                    </div>
                </Modal>
            </div>

            {/*<div class="success-page">*/}
            {/*    <h2>Payment Successful !</h2>*/}
            {/*    <p>We are delighted to inform you that we received your payments</p>*/}
            {/*</div>*/}


            <section id="contact" className="sec-contact">
                <div className="container">
                    <h2>Get In Touch!</h2>

                    <hr/>

                    <div className="row">
                        <div className="col-sm-6 offset-sm-3">
                            <form id={"myForm"} className="center-block">
                                <div className="form-group">
                                    <label className="sr-only" htmlFor={"inputName"}>Full name</label>

                                    <input id="inputName" className="form-control" type="text" placeholder="Name" required/>
                                </div>

                                <div className="form-group">
                                    <label for="inputMail" className="sr-only">Email Address</label>

                                    <input id="inputMail" className="form-control" type="email" placeholder="Email" required/>
                                </div>

                                <div className="form-group">
                                    <label for="inputTel" className="sr-only">Mobile Number</label>

                                    <input id="inputTel" className="form-control" type="tel" placeholder="Mobile" required/>
                                </div>

                                <div className="form-group">
                                    <label for="inputMessage" className="sr-only">Your Message</label>

                                    <textarea id="inputMessage" className="form-control" placeholder="Your message here..." name="message" cols="30" rows="8" required></textarea>
                                </div>

                                <div className="form-group">
                                    <button className="btn btn-primary center-block submitButton" id="submit" type="submit" value="Submit" onClick={onSubmit}>Submit
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </section>




            <section id="footer">
                <div className="container">
                    <ul className="soc-media-ul">

                            <a href={"https://www.facebook.com/manas.ram.73"}><img style={{height: "30px", width: "30px"}} src={fb}/></a>
                            <a href={"https://www.instagram.com/manasram1/"}><img style={{height: "30px", width: "30px"}} src={insta}/></a>
                           {/*<a href={"https://twitter.com/manasram"}><img style={{height: "30px", width: "30px"}} src={twitter}/></a>*/}
                            <a href={"https://www.linkedin.com/in/manas-ram-60a27a9a"}><img style={{height: "30px", width: "30px"}} src={linkedin}/></a>
                    </ul>
                    <h6 className="red-text">© 2024 Manas Ram. All rights reserved.</h6>
                </div>
            </section>


        </div>
    );
}

export default App;
